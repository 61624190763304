:root {
  --primary: #91263d;
  --rs-primary-50: var(--primary) !important;
  --rs-primary-100: #eee !important;
  --rs-primary-200: var(--primary) !important;
  --rs-primary-300: var(--primary) !important;
  --rs-primary-400: var(--primary) !important;
  --rs-primary-500: var(--primary) !important;
  --rs-primary-600: var(--primary) !important;
  --rs-primary-700: var(--primary) !important;
  --rs-primary-800: var(--primary) !important;
  --rs-primary-900: var(--primary) !important;
}

body {
  margin: 0;
}

@font-face {
  font-family: "Yummo";
  src: url("fonts/Yummo/Yummo_Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Yummo";
  src: url("fonts/Yummo/Yummo_Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Yummo";
  src: url("fonts/Yummo/Yummo_Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

nav a {
  color: currentColor;
  text-decoration: none;
}

table a {
  color: #91263d;
  text-decoration: none;
  font-weight: 600;
}

.link {
  color: #91263d;
  text-decoration: none;
  font-weight: 600;
}

a:hover {
  text-decoration: underline;
}

.rbc-calendar {
  min-height: 600px;
}

.rbc-toolbar button {
  font-family: Yummo;
}

.rbc-toolbar button.rbc-active {
  box-shadow: none !important;
  background-color: #91263d !important;
  border-color: #91263d !important;
  color: #fff !important;
}

body .rbc-agenda-content tr {
  background: none !important;
}

.pre {
  white-space: pre-wrap;
}

table th,
table td {
  white-space: nowrap;
}

button[aria-haspopup="listbox"] {
  border: 0;
}

[role="tab"][aria-selected="true"] {
  cursor: pointer;
}

.mobileTitle {
  display: none;
}

@media (max-width: 500px) {
  .mobileTitle {
    display: block;
  }
  .desktopTitle,
  .mobileTable th,
  .mobileTable td {
    display: none;
  }
  .mobileTable th:first-child,
  .mobileTable td:first-child {
    display: table-cell;
  }
  .mobileTable th:last-child,
  .mobileTable td:last-child {
    display: table-cell;
  }
}
.productManagerWrapper,
.mobileTableWrapper {
  width: 100%;
  overflow-x: auto;
}

a small {
  font-weight: 400;
  font-size: 75%;
  line-height: 1;
}

[role="menuitem"] {
  white-space: nowrap;
}

@media (min-width: 767px) {
  .filepond--item {
    width: calc(50% - 0.5em);
  }
}

@media (min-width: 1200px) {
  .filepond--item {
    width: calc(25% - 0.5em);
  }
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  height: 39px;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
  color: currentColor;
}

.grid {
  display: grid;
  grid-gap: 25px;
}

@media (min-width: 1400px) {
  .columns_2,
  .columns_3,
  .columns_4 {
    grid-template-columns: 1fr 1fr;
  }

  .columns_2_3 {
    grid-template-columns: 2fr 1fr;
  }
}

@media (min-width: 768px) {
  .columns_3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .columns_4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  min-width: 15ch;
  max-width: 30ch;

  border: 0;
  padding: 0.25em 0.5em;

  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
}

.select::after {
  content: "";
  justify-self: end;
  align-self: center;
  width: 0.8em;
  height: 0.8em;
  background-color: #91263d;
  clip-path: polygon(
    100% 27.25%,
    50% 77.25%,
    0% 27.25%,
    21.25% 27.25%,
    50% 56.84%,
    78.75% 27.25%
  );
}

select,
.select:after {
  grid-area: select;
}

option {
  color: currentColor;
}

.gantt {
  line-height: 1;
}

/* remove line number plus the gap div */
.editable-list > li > span:first-child,
.editable-list > li > span:first-child + div {
  display: none;
}

.editable-list > li > span + div + div > div:first-child,
.editable-list > li > span + div + div > div:first-child > div {
  width: 100%;
}

.truncate {
  max-width: 360px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rs-picker-popup {
  z-index: 9999 !important;
}

@media (max-width: 768px) {
  .rs-picker-daterange-menu {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
  }

  .rs-picker-daterange-menu .rs-picker-daterange-calendar-group {
    display: grid;
    height: 540px !important;
  }

  .rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: 0 !important;
    border-bottom: 1px solid var(--rs-border-primary);
  }

  .rs-picker-toolbar {
    position: absolute;
    left: 0;
    right: 0;
  }
}
@media (min-width: 769px) {
  .rs-picker-daterange-menu {
    transform: translate(4px, -40px);
  }
}
.rs-picker-menu {
  font-family: Yummo;
}
.rs-picker-toggle-wrapper {
  width: 100%;
}
.rs-picker-menu {
  z-index: 999 !important;
}

.markdown > :first-child {
  margin-top: 0;
}

.react-pdf__Page__canvas {
  border: 1px solid #eee;
}

.react-pdf__Page {
  margin-top: 20px;
}
